"use strict";

import {findIn, findAllIn, addClass, on, removeClass, removeAttribute, setAttribute, hasClass} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
    onFind('.js-scroll-slider', function (container) {
        let prevButton = findAllIn('.js-scroll-slider__prev', container),
            nextButton = findAllIn('.js-scroll-slider__next', container),
            scrollArea = findIn('.js-scroll-slider__area', container),
            dotsContainer = findIn('.js-scroll-slider__dots', container);


        let items = findAllIn('.js-scroll-slider__item', container);
        const scrollWidth = getScrollWidth(items[0]);
        const offset = items[0].getBoundingClientRect().left;
        let lastItemActive = false;

        //initial scroll position 0
        scrollArea.scrollLeft = 0;

        nextButton.map((button) => {
            on('click', function () {
                scrollTo(scrollArea, scrollArea.scrollLeft + scrollWidth)
            }, button);
        });

        prevButton.map((button) => {
            if(scrollArea.scrollLeft === 0) {
                setAttribute('disabled', true, button);
            } else {
                removeAttribute('disabled', button);
            }

            on('click', function () {
                if (lastItemActive) {
                    scrollTo(scrollArea, scrollArea.scrollLeft - scrollWidth/2)
                } else {
                    scrollTo(scrollArea, scrollArea.scrollLeft - scrollWidth)
                }
            }, button);
        });

        items.map((item, index) => {
            if (dotsContainer) {
                let newDot = defaultDotsRenderFunction(index, dotsContainer);

                on('click', function () {
                    scrollTo(scrollArea, Math.floor(item.getBoundingClientRect().left) + scrollArea.scrollLeft - Math.floor(scrollArea.getBoundingClientRect().left))
                }, newDot);
            }
        });

        let dots;
        if (dotsContainer) {
            dots = findAllIn('.js-scroll-slider__dots-btn', dotsContainer);
        }

        let currentScroll = 0;
        on('scroll', function (evt) {
            if (dots) {
                dots.map((item) => removeClass('is-active', item));
            }

            nextButton.map((button) => removeAttribute('disabled', button));
            lastItemActive = false;

            if(scrollArea.scrollLeft === 0) {
                prevButton.map((button) => setAttribute('disabled', true, button));
            } else {
                prevButton.map((button) => removeAttribute('disabled', button));
            }

            items.map((item, index) => {

                if (item.getBoundingClientRect().left <= scrollWidth && item.getBoundingClientRect().left >= offset) {
                    if (items[index - 1]) {
                        removeClass('is-active', items[index - 1]);
                        addClass('is-before-active', items[index - 1]);
                    }

                    addClass('is-active', item);

                    if (items.slice(-1)[0] === item) {
                        nextButton.map((button) => setAttribute('disabled', true, button));
                        lastItemActive = true;
                    }

                    if (dots) {
                        addClass('is-active', dots[index]);
                    }

                } else if(item.getBoundingClientRect().left >= scrollWidth && item.getBoundingClientRect().left <= container.getBoundingClientRect().width) {
                    if (items[index - 1]) {
                        addClass('is-active', items[index - 1]);
                        removeClass('is-before-active', items[index - 1]);
                    }

                    removeClass('is-active', item);
                }
            });
        }, scrollArea);

        updateScrollWidth(items[0]);
    });
}

function scrollTo(scrollArea, scroll) {
    try {
        scrollArea.scroll({
            left: scroll,
            behavior: 'smooth'
        });
    } catch (e) {
        scrollArea.scrollLeft = scroll;
    }
}

function getScrollWidth(item) {
    return item.getBoundingClientRect().width;
}

function updateScrollWidth(item) {
    let delay = 250,
        throttled = false;

    window.addEventListener('resize', function() {
        if (!throttled) {
            getScrollWidth(item);
            throttled = true;
            setTimeout(function() {
                throttled = false;
            }, delay);
        }
    });
}

function defaultDotsRenderFunction(id = null, dotContainer) {
    let markupElement = document.createElement('div'),
        dotMarkup = `<button type="button" class="js-scroll-slider__dots-btn ${id === 0 ? 'is-active' : ''}" data-id="${id+1}"></button>`;
    markupElement.innerHTML = dotMarkup;
    let newDot = markupElement.childNodes[0];
    dotContainer.appendChild(newDot);

    return newDot;
}

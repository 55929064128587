import './scripts/common';
import './scripts/common-nav';

let _config = window['_config'] || {};
import {translate} from '@elements/translations';
import {findIn, find} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

let app = {
    debug: false,
};
window.app = app || {};

import {createApp} from 'vue';
import BookingQuickfinder from './scripts/components/VueBookingQuickfinder.vue';
const vueApp = createApp({
    components: {
        BookingQuickfinder
    }
})
vueApp.mount('#vue-booking-quickfinder');

// Todo: Add page specific JS here e.g:
import * as lightbox from '@elements/lightbox';
lightbox.init();

import * as datepicker from "@elements/datepicker";
datepicker.init();

import * as datepickerRange from '@elements/datepicker-range';
datepickerRange.init();

import * as playVideo from './scripts/play-video';
playVideo.init();

import * as scrollSlider from './scripts/scroll-slider-extended';
scrollSlider.init({
    arrowIconClass:"icon icon-chevron-right",
    dots: true,
    arrowRenderFunction: arrowRenderFunction
}, {
    base: '.js-scroll-slider',
    area: '.js-scroll-slider__area',
    item: '.js-scroll-slider__item',
});
import * as sliderDotsAnimations from './scripts/slider-dots-animation'
sliderDotsAnimations.init()

function arrowRenderFunction(container, direction, disabled, options) {
    let markupElement = document.createElement('div'),
        arrowMarkup = `<button type="button" class="${options.arrowClass} ${direction === 0 ? options.arrowPrevClass : options.arrowNextClass}" ${disabled ? 'disabled' : ''}><span class="scroll-slider__arrow-icon icon icon-chevron ${direction === 0 ? 'icon-rotate-90' :  'icon-rotate-270' }" aria-label="${translate('scroll-slider-'+ direction + '')}" title="${translate('scroll-slider-'+ direction + '')}"></span></button>`;
    markupElement.innerHTML = arrowMarkup;
    let newArrow = markupElement.childNodes[0];
    let arrowContainer = findIn('.js-scroll-slider__arrow-wrapper', container);
    arrowContainer.appendChild(newArrow);
    return newArrow;
}

import * as scrollSliderCounter from './scripts/scroll-slider-counter';
scrollSliderCounter.init();

import * as anchorNav from '@elements/anchor-nav/bootstrap-5';
anchorNav.init(
    {
        renderItem: function (text, anchor) {
            return '<li class="nav-item anchor-nav__item js-anchor-nav__item"><a class="nav-link js-smoothscroll__link js-anchor-nav__link anchor-nav__link" href="#' + anchor + '"><span class="anchor-nav__item-icon icon icon-chevron"></span>' + text + '</a></li>';
        },
        smoothScroll: false,
        scrollSpy: true,
        scrollSpyTarget: '.js-anchor-nav',
        scrollSpyOffset: (matchMedia('(min-width: 768px)').matches ? 481 : 51)
    },
    {
        list: '.js-anchor-nav__list',
        item: '.js-anchor-nav__item'
    }
);

import * as smoothScroll from "@elements/smooth-scroll";
smoothScroll.init({
    scrollOffset: (matchMedia('(min-width: 768px)').matches ? 480 : 50),
});

import Tab from 'bootstrap/js/dist/tab';
onFind('[data-bs-toggle="tab"]',function (element) {
    new Tab(element);
    element.addEventListener('show.bs.tab', function(event){
        forceRecalculation();
    }, false);
});

import * as toggleActive from './scripts/toggle-active';
toggleActive.init();

import * as affix from "@elements/affix";
affix.init();
import {forceRecalculation} from "@elements/affix";

// import * as externalVideo from './scripts/external-video';
// externalVideo.init();

import * as hotspot from './scripts/hotspot';
hotspot.init();

import * as formValidation from '@elements/form-validation';
formValidation.init();